<template>
  <div class="menu_data white">
    <ToolBar />
    <div>
      <v-container class="white">
        <v-row class="blue lighten-5" dense>
          <v-col cols="12" class="pa-0">
            <v-sheet color="grey lighten-4">
              <v-chip class="" color="red darken-1" label text-color="white">
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขอั้น 3ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                color="white"
                text-color="indigo darken-4"
                v-for="(item, i) in hp_three_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>

          <v-col cols="12" class="pa-0">
            <v-sheet class="mt-1" color="grey lighten-4">
              <v-chip class="mr-1" color="red" label text-color="white">
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขอั้น 2ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                color="white"
                text-color="indigo darken-4"
                v-for="(item, i) in hp_two_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>

          <v-col cols="12" class="pa-0">
            <v-sheet class="mt-1" color="grey lighten-4">
              <v-chip class="mr-1" color="pink" label text-color="white">
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขเต็ม3ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                color="white"
                text-color="indigo darken-4"
                v-for="(item, i) in limit_three_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>

          <v-col cols="12" class="pa-0">
            <v-sheet class="mt-1" color="grey lighten-4">
              <v-chip class="mr-1" color="pink" label text-color="white">
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขเต็ม2ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                color="white"
                text-color="indigo darken-4"
                v-for="(item, i) in limit_two_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <!-- ข้อมูล -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ข้อมูลงวด</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_data_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{item.icon}}</v-icon>
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- ตีออกเ -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ตีออก</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_export_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{item.icon}}</v-icon>
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- ตั้งค่า -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ตั้งค่างวด</v-subheader
            ></v-col
          >
          <v-col
            cols="3"
            md="3"
            v-for="(item, i) in menu_setting_item"
            :key="i"
          >
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{item.icon}}</v-icon>
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- รายงาน -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >รายงาน</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_report_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{ item.icon}}</v-icon>
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <!-- <v-divider></v-divider> -->
        <v-card height="50" flat> </v-card>
      </v-container>
    </div>

    <BottomNavigation ref="b_nav" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import ToolBar from "@/components/Layouts/ToolBar";
import BottomNavigation from "@/components/Layouts/BottomNavigation";

export default {
  name: "Menu_Data",
  mixins: [globalFunctionMixin],
  components: {
    BottomNavigation,
    ToolBar,
    // SideBar,
  },
  mounted() {
    this.ckLogin();
    this.$refs.b_nav.SetID(0);
    window.scrollTo(0, 0);
    this.fetch_data();
  },
  methods: {
    //ดึงเลขอั้น แล้ว refresh ทุก 2 นาที
    async fetch_data() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
      };
      this.$store.dispatch("hp/getHPShow", credentials).then(
        (response) => {
        //   console.log(response);
          this.hp_three_item = [];
          this.hp_two_item = [];
          this.limit_three_item = [];
          this.limit_two_item = [];
          for (let Hp of response.HPList) {
            let NewHP = {};
            switch (Hp["BetTypeDetailID"]) {
              case "1": //3 บน        
                NewHP["text"] = Hp["BetNumber"];
                NewHP["convert"] = true;
                this.hp_three_item.push(NewHP);
                break;
              case "2": //3โต๊ด
                // console.log("3 โต๊ด");
                // console.log(Hp);
                break;
              case "4": //2 บน+ล่าง
                NewHP["text"] = Hp["BetNumber"];
                NewHP["convert"] = true;
                this.hp_two_item.push(NewHP);
                break;
            }
            //console.log(Hp);
            // text += person[x];
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  data: () => ({
    hp_three_item: [],
    hp_two_item: [],
    limit_three_item: [],
    limit_two_item: [],
    menu_data_item: [
      { text: "ภาพรวม", icon: "mdi-database", route: "/dashboard" },
      { text: "ยอดรวม", icon: "mdi-sigma", route: "/limit_number" },
      { text: "กำไรขาดทุน", icon: "mdi-currency-usd", route: "/limit_number" },
      { text: "ค้นหาตัวซื้อ", icon: "mdi-file-find", route: "/find_bet" },
      { text: "เลขอั้น", icon: "mdi-percent", route: "/hp_number" },
      {
        text: "เลขเต็ม",
        icon: "mdi-alpha-x-circle-outline",
        route: "/limit_number",
      },
      {
        text: "ตั้งขาดทุน",
        icon: "mdi-wallet-outline",
        route: "/limit_number",
      },
      {
        text: "time line",
        icon: "mdi-chart-timeline",
        route: "/limit_number",
      },      
    ],
    menu_export_item: [
      { text: "ตั้งเจ้ามือ", icon: "mdi-account-tie", route: "/bookie" },
      {
        text: "ตัดส่งเจ้า",
        icon: "mdi-chart-areaspline",
        route: "/export_begin",
      },
      { text: "โพยตีออก", icon: "mdi-file", route: "/export_page" },
      { text: "ยอดตีออก", icon: "mdi-cash-100", route: "/export_summary" },
    ],
    menu_setting_item: [
      { text: "เปิด/ปิดงวด", icon: "mdi-tune", route: "/period_setting" },
      {
        text: "ประมวลผล",
        icon: "mdi-license",
        route: "/limit_number",
      },
    ],
    menu_report_item: [
      {
        text: "ใบปะหน้า",
        icon: "mdi-microsoft-onenote",
        route: "/limit_number",
      },
      {
        text: "บัญชีรับ-จ่าย",
        icon: "mdi-text-account",
        route: "/limit_number",
      },
    ],
    test: true,
  }),
};
</script>

<style scoped>

</style>
